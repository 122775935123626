import AxiosNode from '@/services/api/node/axios_node'

export default {
  getEnablers: (params) => {
    return AxiosNode.get('/enabler', { params })
  },

  createEnabler: (data) => {
    return AxiosNode.post('/enabler', data)
  },

  updateEnabler: (data, id) => {
    return AxiosNode.put(`/enabler/${id}`, data)
  },

  getEnablersAttached: (nodeId, params) => {
    return AxiosNode.get(`/enabler/attached/${nodeId}`, { params })
  },

  getEnablersByNode: (nodeId, params) => {
    return AxiosNode.get(`/enabler/node/${nodeId}`, { params })
  },
}
