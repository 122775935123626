export default {
  sortedFieldsList: (state) => {
    let sorted = state.fieldsList.sort(function(a, b) {
      return a.field_key.toUpperCase() < b.field_key.toUpperCase() ? -1 : 1;
    });
    return sorted;
  },
  sortedGroupsList: (state) => {
    let sorted = state.groupsList.sort(function(a, b) {
      return a.group_title.toUpperCase() < b.group_title.toUpperCase() ? -1 : 1;
    });
    return sorted;
  },
};
