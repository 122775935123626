import grant from './grant'
import nodetoken from './nodetoken'
import field from './field'

export default {
  namespaced: true,
  modules: {
    grant,
    nodetoken,
    field,
  }
}
