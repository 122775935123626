import api from '@/services/api'
import FieldActions from './actions-types'

export default {
  [FieldActions.GET_NODE_FIELD_VALUES]({}, nodeId) {
    return new Promise((resolve, reject) => {
      api.node.field
        .getNodeFieldValues(nodeId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },

  [FieldActions.SAVE_FORM_VALUES]({}, { nodeId, data }) {
    return new Promise((resolve, reject) => {
      api.node.field
        .saveFormValues(nodeId, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
}
