import AxiosNode from '@/services/api/node/axios_node'

export default {
  getTenantFields: (tenantId) => {
    return AxiosNode.get(`/field/tenant/${tenantId}`)
  },

  getNodeFieldValues: (nodeId) => {
    return AxiosNode.get(`/field/node/${nodeId}/fieldvalue`)
  },

  saveFormValues: (nodeId, data) => {
    return AxiosNode.post(`/form/node/${nodeId}`, data)
  },
}